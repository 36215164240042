import React, { useCallback, useMemo, useState } from 'react'
import { HeaderGlobals } from '../../../globals/functions/header'
import { PrimaryNavigation } from '../../PrimaryNavigation/PrimaryNavigation'
import { NavMenu } from '../../PrimaryNavigation/NavMenu'
import { NavItem } from '../../PrimaryNavigation/NavItem'
import { NavDropdownLinks } from '../../NavigationDropdown/NavDropdownLinks'
import { NavDropdownUtilityBar } from '../../NavigationDropdown/NavDropdownUtilityBar'
import { NavDropdownPanel } from '../../NavigationDropdown/NavDropdownPanel'
import { CreditUnionIcon, IconCodeType } from '../../icon/CreditUnionIcon'
import { MobileMenu } from '../../MobileMenu/MobileMenu'
import { StyledMobileMenuUtility } from '../../MobileMenu/styledMobileMenuUtility'
import {
  MobileMenuBackItem,
  MobileMenuHeader,
  MobileMenuLinkItem,
  MobileMenuUtilityItem,
} from '../../MobileMenu/MobileMenuItem'
import { Grid, Link } from '@material-ui/core'
import {
  StyledOverviewChevron,
  StyledOverviewContainer,
  StyledOverviewTitle,
} from '../../NavigationDropdown/styledNavDropdownUtilityBar'
import { CircleIcon } from '../../NavigationDropdown/circleIcon'
import { useRouter } from 'next/router'

export type HeaderProps = HeaderGlobals

const ICON_GROUP_TYPE = 'modules\\megaMenu\\nodes\\IconGroup' as const

export const HeaderFragment: React.VFC<HeaderProps> = ({ navigation }) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const secondary = useMemo(() => router.pathname !== '/', [router])
  const toggleMenu = useCallback(() => setOpen(!open), [setOpen, open])
  const closeMenu = useCallback(() => setOpen(false), [setOpen])
  let idx = 0

  return (
    <>
      <PrimaryNavigation BSB={'805 007'} secondary={secondary} openMobileMenu={toggleMenu}>
        <NavMenu>
          {navigation.nodes.map((node, i) => {
            const menus = node.children.filter((node) => node.type !== ICON_GROUP_TYPE)
            const iconGroup = node.children.find((node) => node.type === ICON_GROUP_TYPE)

            return (
              <NavItem
                key={i}
                title={node.title}
                aria-haspopup={node.children ? 'true' : 'false'}
              >
                {menus.length > 0 ? (
                  <NavDropdownPanel title={node.title}>
                    <Grid item>
                      <StyledOverviewContainer>
                        {/* @ts-ignore */}
                        <Link href={node.url} passHref>
                          <StyledOverviewTitle>
                            {node.title} overview page
                          </StyledOverviewTitle>
                          <StyledOverviewChevron>
                            <CircleIcon />
                          </StyledOverviewChevron>
                        </Link>
                      </StyledOverviewContainer>
                    </Grid>

                    <Grid item>
                      <NavDropdownLinks>
                        {menus.map((node, i) => (
                          <NavDropdownLinks card key={i} title={node.title} href={node.url || '#'}>
                            {node.children?.map((node, i) => (
                              <NavDropdownLinks item key={i} href={node.url}>
                                {node.title}
                              </NavDropdownLinks>
                            ))}
                          </NavDropdownLinks>
                        ))}
                      </NavDropdownLinks>
                    </Grid>

                    {iconGroup && (
                      <Grid item>
                        <NavDropdownUtilityBar title={iconGroup.title} href={iconGroup.url || '#'}>
                          {iconGroup.children?.map((node, i) => (
                            <NavDropdownUtilityBar card key={i} title={node.title} iconCode={node.data.icon as IconCodeType} href={node.url || '#'} />
                          ))}
                        </NavDropdownUtilityBar>
                      </Grid>
                    )}
                  </NavDropdownPanel>
                ) : null}
              </NavItem>
            )
          })}
        </NavMenu>
      </PrimaryNavigation>
      <MobileMenu wrapper portal={'#mobile-menu'} isMenuOpen={open}>
        <MobileMenu navigation level={0} idx={idx++}>
          <MobileMenuHeader phoneNumber={'8202 7777'} bsbNumber={'805 007'} onClose={closeMenu} />
          {navigation.nodes.map((node, i) => {
            const menus = node.children.filter((node) => node.type !== ICON_GROUP_TYPE)
            const iconGroup = node.children.find((node) => node.type === ICON_GROUP_TYPE)
            const title = `${node.title} overview`

            return (
              <MobileMenu item key={i} name={node.title} level={0} idx={idx++} hasChildren={menus.length > 0}>
                <MobileMenu navigation level={1} idx={idx}>
                  <MobileMenuBackItem level={1} idx={idx}>Back</MobileMenuBackItem>
                  <MobileMenuLinkItem href={node.url || '#'} isTitle>
                    {title} page
                    {menus.length > 0 && (
                      <StyledOverviewChevron>
                        <CircleIcon />
                      </StyledOverviewChevron>
                    )}
                  </MobileMenuLinkItem>
                  {menus.length > 0 && menus.map((node, i) => (
                    <MobileMenu key={i} item name={node.title} level={1} idx={idx++} hasChildren={node.children.length > 0}>
                      <MobileMenu navigation level={2} idx={idx}>
                        <MobileMenuBackItem level={2} idx={idx}>
                          {title}
                        </MobileMenuBackItem>
                        <MobileMenuLinkItem href={node.url || '#'} isTitle>
                          {node.title}
                          <StyledOverviewChevron>
                            <CircleIcon />
                          </StyledOverviewChevron>
                        </MobileMenuLinkItem>
                        {node.children.map((node, i) => (
                          <MobileMenuLinkItem key={i} href={node.url || '#'}>
                            {node.title}
                          </MobileMenuLinkItem>
                        ))}
                      </MobileMenu>
                    </MobileMenu>
                  ))}
                  {iconGroup && (
                    <StyledMobileMenuUtility>
                      {iconGroup.children.map((node, i) => (
                        <MobileMenuUtilityItem key={i} href={node.url || '#'} icon={<CreditUnionIcon iconCode={node.data.icon as IconCodeType} />}>
                          {node.title}
                        </MobileMenuUtilityItem>
                      ))}
                    </StyledMobileMenuUtility>
                  )}
                </MobileMenu>
              </MobileMenu>
            )
          })}
        </MobileMenu>
      </MobileMenu>
    </>
  )
}