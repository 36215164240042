import React, { ComponentProps, useContext } from 'react'
import styled from 'styled-components'
import { PrimaryNavigationContext } from './PrimaryNavigation'

export type NavItemProps = ComponentProps<typeof StyledNavItem> & {
  title: string | unknown
  focus?: boolean
}

//change to theme color with: 
export const StyledNavItem = styled.div<{ $focus?: boolean; show?: boolean }>`
  display: inline-block;
  opacity: ${props => props.show ? 1 : 0};
  padding-left: ${props => props.theme.typography.pxToRem(10)};
  padding-right: ${props => props.theme.typography.pxToRem(10)};
  will-change: opacity;
  transition: opacity 0.15s ease-in-out;

  &::after {
    content: '';
    width: ${props => props.$focus ? props.theme.typography.pxToRem(25) : '0'};
    height: 5px;
    opacity: ${props => props.$focus ? 1 : 0.25};
    transform: translateY(20px);
    display: block;
    background: ${props => props.theme.palette.primary.main};
    transition: 300ms;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    opacity: 1;
    width: 100%;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: ${props => props.theme.typography.pxToRem(30)};
    padding-right: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledNavItemText = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(18)};
  text-decoration: none;
`

export const NavItem: React.FC<NavItemProps> = ({ title, children, focus=false }) => {
  const { searchIsOpen } = useContext(PrimaryNavigationContext)

  return (
    <StyledNavItem $focus={focus} show={searchIsOpen}>
      <StyledNavItemText>{ title }</StyledNavItemText>
      { children }
    </StyledNavItem>
  )
}
